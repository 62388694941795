<template>
  <div class="mr-4">
    <v-subheader>Job Infomation</v-subheader>
    <v-row>
      <v-col cols="4">
        <div class="file-wrapper">
          <v-row>
            <v-icon class="iconUpload"
              size="100px"
              @click="openMap = true">mdi-map</v-icon>
          </v-row>
          <v-row>
            <h5 class="caption iconUpload">Click open map</h5>
          </v-row>
        </div>
        <div class="mt-2">
          <strong>Have Location : </strong><v-chip small>{{ jobInfo.location.coordinates.length }} Locations.</v-chip>
        </div>
      </v-col>
      <v-col cols="8">
          <v-text-field
            label="Job Name"
            v-model="jobInfo.name"
            dense
            outlined
            required />
        <v-row>
          <v-col cols="6"
              class="mt-2">
            <v-menu
              v-model="jobDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  label="Date Start Job"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="jobDate = false" />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-switch
              label="Activate Job"
              color="info"
              v-model="jobInfo.activated"
              hide-details />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      width="850"
      v-model="openMap">
      <v-card>
        <v-card-title>
          <v-col cols="4">Select Location For Job</v-col>
          <v-col cols="7">
            <gmap-autocomplete
              id="searchPlace"
              class="ml-5"
              :select-first-on-enter="true"
              @place_changed="setPlace"
              type="text">
              <!-- <template v-slot:input="slotProps">
                <v-text-field
                  outlined
                  prepend-inner-icon="mdi-map-marker-radius-outline"
                  prepend-outer-icon="mdi-map-search"
                  placeholder="Location Of Event"
                  ref="searchPlace"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs"
                  @click:append-outer="userPlace">
                </v-text-field>
              </template> -->
            </gmap-autocomplete>
          </v-col>
        </v-card-title> 

        <v-card-text>
          <GmapMap
            :center="center"
            :zoom="15"
            ref="map"
            style="width: 100%; height: 500px">
            <Gmap-Marker v-if="this.place" 
              :label="place.name" 
              :position="{
                lat: this.place.geometry.location.lat(),
                lng: this.place.geometry.location.lng(),
              }"></Gmap-Marker>
            <gmap-polygon
              v-if="paths.length > 0"
              :paths="paths"
              :editable="true"
              @paths_changed="updateEdited($event)"
              @rightClick="handleClickForDelete" />
          </GmapMap>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary"
            outlined
            @click="addPath()">
            <v-icon class="mr-5">mdi-map-marker-path</v-icon> Add Path</v-btn>
          <v-btn
            color="deep-orange lighten-4"
            outlined
            @click="removePath()">
            <v-icon class="mr-5">mdi-map-marker-remove-outline</v-icon> Remove Path</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-1"
            outlined
            @click="openMap = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from 'moment';
import _ from 'lodash'
// import $Scriptjs from 'scriptjs';
// import { geofrence } from '../../helpers/config'

export default {
  name: 'CreateAutomateGeoFrence',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data () {
    return  {
      openMap: false,
      date: moment().format('YYYY-MM-DD'),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      jobDate: false,
      jobTime: '',
      time: '08:00',
      ids: '',
      msisdn: '',
      map: null,
      showInfo: false,
      markers: [],
      place: null,
      center: {
        lat: 13.733101244359595,
        lng: 100.53061231331992,
      },
      zoom: 15,
      edited: null,
      paths: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: '',
    }
  },

  mounted() {
    // $Scriptjs(`https://maps.googleapis.com/maps/api/js?key=${geofrence.apiKey}&libraries=geometry,places`, () => {
    //   this.initMap();
    // });

    // eslint-disable-next-line no-return-assign
    this.$refs.mapRef.$mapPromise.then(map => this.map = map);
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    date(val) {
      this.dateFormatted = this.formatDate(this.date);

      const dayStart = new Date(`${this.date}T${this.time}`);

      this.jobInfo.startTime = Date.parse(dayStart);
      this.jobInfo.endTime = this.jobInfo.startTime + (1000 * 10);
    },

    polygonPaths: _.throttle(function (paths) {
      if (paths) {
        this.paths = paths;

        this.polygonGeojson = JSON.stringify({
          type: 'Polygon',
          coordinates: this.paths.map(path => this.closeLoop(path.map(({lat, lng}) => [lng,lat])))
        }, null, 2)

        this.jobInfo.location.coordinates = this.paths.map(path => this.closeLoop(path.map(({lat, lng}) => [lng, lat])));
      }
    }, 1000),
  },

  computed: {
    polygonPaths() {
      if (!this.mvcPaths) return null

      const paths = [];
      // eslint-disable-next-line no-plusplus
      for (let i=0; i < this.mvcPaths.getLength(); i++) {
        const path = [];
        // eslint-disable-next-line no-plusplus
        for (let j=0; j<this.mvcPaths.getAt(i).getLength(); j++) {
          const point = this.mvcPaths.getAt(i).getAt(j);
          path.push({lat: point.lat(), lng: point.lng()});
        }
        paths.push(path);
      }
      return paths
    },

    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },
  },

  methods: {
    closeLoop(path) {
      return path.concat(path.slice(0, 1));
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: {
          lat: this.position.lat,
          lng: this.position.lng
        },
        zoom: 15,
      });
    },

    updateEdited(mvcPaths) {
      this.mvcPaths = mvcPaths;
    },

    addPath() {
      const bounds = this.$refs.map.$mapObject.getBounds()
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      const center = bounds.getCenter()
      const degree = this.paths.length + 1;
      // eslint-disable-next-line no-restricted-properties
      const f = Math.pow(0.66, degree)

      const path = [
        { lng: center.lng(), lat: (1-f) * center.lat() + (f) * northEast.lat() },
        { lng: (1-f) * center.lng() + (f) * southWest.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
        { lng: (1-f) * center.lng() + (f) * northEast.lng(), lat: (1-f) * center.lat() + (f) * southWest.lat() },
      ];

      this.paths.push(path);
    },

    removePath() {
      this.paths.splice(this.paths.length - 1, 1);
    },

    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject.getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },

    setDescription(description) {
      this.description = description;
    },

    updateCenter() {
      this.center = {
        // eslint-disable-next-line no-undef
        lat: this.place.geometry.location.lat(),
        // eslint-disable-next-line no-undef
        lng: this.place.geometry.location.lng()
      }
    },

    setPlace(place) {
      this.place = place;

      this.updateCenter();
    }
  }
}
</script>

<style lang="scss" scoped>
  #map {
    width: 150vw;
    height: 50vw;
  }

  #searchPlace {
    width: 100%;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    font-size: small;
    font-weight: normal;
  }

  .file-wrapper {
    text-align: center;
    margin: auto auto;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    min-width: 270px;
    height: 150px;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    overflow: hidden; 
      input {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
      }

      .iconUpload {
        margin: auto auto;
      }
  }

  .fileList {
    color: #039BE5;
    list-style-type: none;
  }

</style>